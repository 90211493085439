import React, {useEffect, useState} from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/sb-admin-2.css';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import ApiClient from './api-client';
import AuthConfig from './auth-config';
import MediaExpenses from './pages/media-expenses';
import Pacing from './pages/pacing';
import Margins from './pages/margins';
import Authentication from './pages/authentication';
import Logout from './pages/logout';
import Profile from './pages/profile';
import System from './pages/system';
import ManagementFees from './pages/management-fees';
import AuditTrail from './pages/audit-trail';
import {jwtDecode} from "jwt-decode";

function UiWrapper() {

    const [roles, setRoles] = useState(null);
    const [authInitialized, setAuthInitialized] = useState(false); // Avoid initialization race condition on AuthConfig.getPublicClientApplication() singleton
    
    useEffect(() => {
        async function checkToken() {
            let publicClientApplication = await AuthConfig.getPublicClientApplication();
            await publicClientApplication.handleRedirectPromise();
            
            if (AuthConfig.isAuthenticatedPath()) {
                let apiClient = new ApiClient();
                let token = await apiClient.getToken('redirect');
                let decodedToken = jwtDecode(token);
                setRoles(decodedToken.roles);
            }
            setAuthInitialized(true);
        }

        checkToken();
    }, []);
    
    if (AuthConfig.isAuthenticatedPath() && !authInitialized) {
        return <div></div>
    }
    
    const DefaultRedirect = () => {
        if (roles.includes('mxs.budget.read')) return <Navigate to="/budget" replace />;
        if (roles.includes('mxs.pacing.read')) return <Navigate to="/pacing" replace />;
        if (roles.includes('mxs.margins.read')) return <Navigate to="/margins" replace />;
        return <Navigate to="/profile" replace />;
    };
    
    return (
        <React.StrictMode>
            <div className="ui-wrapper">
                <ToastContainer autoClose={10000} />
                {
                    <BrowserRouter>
                        <Routes>
                            { !!roles && roles.includes('mxs.budget.read') && <Route path="/budget" element={<MediaExpenses/>}/> }
                            { !!roles && roles.includes('mxs.pacing.read') != null && <Route path="/pacing" element={<Pacing/>}/> }
                            { !!roles && roles.includes('mxs.margins.read') != null && <Route path="/margins" element={<Margins/>}/> }
                            { !!roles && roles.includes('mxs.write') != null && <Route path="/management-fees" element={<ManagementFees/>}/> }
                            { !!roles && roles.includes('mxs.write') != null && <Route path="/audit-trail" element={<AuditTrail/>}/> }
                            { !!roles && <Route path="/profile" element={<Profile/>}/> }
                            { !!roles && <Route path="/system" element={<System/>}/> }
                            <Route path="/authentication" element={<Authentication/>}/>
                            <Route path="/logout" element={<Logout/>}/>
                            <Route path="*" element={<DefaultRedirect  />} />
                        </Routes>
                    </BrowserRouter>
                }
            </div>
        </React.StrictMode>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UiWrapper />
);
