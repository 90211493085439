import React, {useEffect, useState} from 'react';
import ApiClient from "../api-client";
import { jwtDecode } from "jwt-decode";

function Profile() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState('');
    useEffect(() => {
        async function setUser() {
            let token = await new ApiClient().getToken();
            let decodedToken = jwtDecode(token);
            setFullName(decodedToken.name);
            setEmail(decodedToken.email);
            setRoles(decodedToken.roles.join(', '));
        }
        setUser();
    }, [setFullName, setEmail, setRoles])
    return (
        <div className="bg-gradient-primary logout" style={{height: '100%'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-5 d-none d-lg-block bg-login-image"
                                         style={{backgroundImage: "url('/img/login.svg')", backgroundSize: '70%', backgroundRepeat: 'no-repeat' }}></div>
                                    <div className="col-lg-7">
                                        <div className="p-5">
                                            <h1 className="h4 text-gray-900 mb-4">Profile</h1>
                                            <div className="form-group">
                                                <input type="text" className="form-control bg-white"
                                                       value={fullName} readOnly={true} />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control bg-white"
                                                       value={email} readOnly={true} />
                                            </div>
                                            <h2 className="h5 text-gray-900 mb-4">Roles</h2>
                                            <div className="form-group">
                                                <input type="text" className="form-control bg-white mb-3"
                                                       value={roles} readOnly={true} />
                                            </div>
                                            <a className="btn btn-primary form-control"
                                               href="/">Close</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-white text-center my-auto">
                            <p>Copyright © 2023 Roger West, LLC.</p>
                            <span className="small">Version 1.0</span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Profile;
